<template>
  <div class="product-section">
    <div class="mt-0">
      <template v-if="$options.components['advertisement-loading']">
        <advertisement-loading v-if="isLoading" />
      </template>
      <div class="container mt-3">
        <b-row class="product-main my-3" v-if="productData">
          <b-col md="5" class="product-border product-images">
            <div
              v-if="productData['Discount'] || productData['Discount(%)']"
              class="show-variant"
            >
              <span v-if="productData['Discount']" class="variant-span">
                ₹{{ productData["Discount"] }} off
              </span>
              <span v-if="productData['Discount(%)']" class="variant-span">
                {{ productData["Discount(%)"] }}% off
              </span>
            </div>
            <b-row>
              <b-col cols="3">
                <div
                  class="productdetail-imgshort"
                  v-for="(product, i) in imagesArr"
                  :key="i"
                  @mouseover="setActiveBox(i)"
                >
                  <b-img
                    :src="product"
                    :class="[
                      {
                        [i === activeBox ? 'box-hovered' : 'box-common']: true,
                      },
                    ]"
                    center
                    height="100"
                    width="100"
                    id="product-img-1"
                    class="product-thumb-image my-3"
                  />
                </div>
              </b-col>
              <b-col cols="7">
                <div class="align-items-center product-main-image-column">
                  <b-img
                    id="product-img-1"
                    class="product-main-image my-3"
                    :src="imagesArr[activeBox]"
                    alt
                    height="320"
                    width="260"
                  />
                </div>
              </b-col>
              <b-col cols="2">
                <div
                  class="d-flex flex-column justify-content-between align-items-center product-icons"
                >
                  <div class="product-icon-circle pointer heart mb-2">
                    <b-icon
                      size="xl"
                      icon="heart-fill"
                      @click="removeItem(productData)"
                      v-if="wishlistKey"
                    ></b-icon>
                    <b-icon
                      size="xl"
                      icon="heart"
                      @click="addToWishlist(productData)"
                      v-else
                    ></b-icon>
                  </div>

                  <!-- <div class="product-icon-circle message">
                    <b-icon size="xl" icon="chat-right-dots-fill"></b-icon>
                  </div>-->
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="7" class="product-info pr-0 pl-3">
            <v-breadcrumbs>
              <div>
                <span
                  class="product-address"
                  @click="$router.push(`${items.href}`)"
                  >{{ items.text }}</span
                >
                <v-icon>mdi-chevron-right</v-icon>
                <span
                  class="product-address"
                  @click="$router.push(`/market/${productData.Market}`)"
                  >{{ `${productData.Market}` }}</span
                >
                <v-icon>mdi-chevron-right</v-icon>
                <span class="text-no-href">{{ `${productData.Name}` }}</span>
              </div>
            </v-breadcrumbs>
            <h1 v-if="productData.Name || productData.Quantity">
              {{ productData.Name }} {{ productData.Quantity }}
            </h1>
            <p class="product-location mb-0" v-if="productData.Brand">
              {{ productData.Brand }}
            </p>
            <div
              class="d-flex mt-3 justify-content-between justify-content-sm-between align-items-center product-price"
            >
              <div
                class="d-flex price-del-width"
                v-if="productData['Discount(%)']"
              >
                <del class="mdi mdi-currency-inr text-icon price mb-0 mr-3">{{
                  productData.Price
                }}</del>
                <h1
                  class="mdi mdi-currency-inr text-icon price mb-0 margin-left-8"
                >
                  {{ productData["Deal Price"] }}
                </h1>
              </div>
              <!-- <h1
                v-if="!productData['Discount(%)']"
                class="mdi mdi-currency-inr text-icon price mb-0 product-price-mobile"
              >
                {{ productData.Price }}
              </h1> -->
              <!-- product price with fixed discount  -->
              <div
                class="d-flex price-del-width"
                v-if="productData['Discount']"
              >
                <del class="mdi mdi-currency-inr text-icon price mb-0 mr-3">{{
                  productData.Price
                }}</del>
                <h1
                  class="mdi mdi-currency-inr text-icon price mb-0 margin-left-8"
                >
                  {{ productData["Deal Price"] }}
                </h1>
              </div>
              <h1
                v-if="!productData['Discount'] && !productData['Discount(%)']"
                class="mdi mdi-currency-inr text-icon price mb-0 product-price-mobile"
              >
                {{ productData.Price }}
              </h1>
              <div id="unit-button-box" v-if="isVariationAvailable">
                <button
                  :class="{
                    selectedUnit:
                      variant.attributes.Quantity === productData.Quantity,
                  }"
                  @click="selectUnit(variant.attributes.Quantity)"
                  v-for="variant in productVariation"
                  :key="variant._id"
                >
                  {{ variant.attributes.Quantity }}
                </button>
              </div>
              <div
                v-if="
                  productData.Availability &&
                  productData.Availability === 'OUT OF STOCK'
                "
              >
                <p class="text-danger font-weight-bold">OUT OF STOCK</p>
              </div>
              <div class="add-button add-button-mobile" id="add-cart" v-else>
                <template
                  v-if="$options.components['checkout-increment-decrement']"
                >
                  <checkout-increment-decrement
                    :callCheckout="false"
                    :cartItem="productData"
                  />
                </template>
              </div>
            </div>
            <!-- product review section -->
            <div
              class="product-rate d-flex justify-content-between align-items-center justify-content-sm-between"
            >
              <h2 class="mb-0">Rate this Product:</h2>
              <div
                class="d-flex justify-content-between align-items-center mr-5"
                v-if="productFeedback"
              >
                <div class="product-stars">
                  <b-form-rating
                    variant="warning"
                    color="#fd7e14"
                    :value="ratingStarCount"
                    readonly
                  ></b-form-rating>
                </div>
                <h2 class="mb-0">({{ productFeedback.ratingCount || "0" }})</h2>
              </div>
              <div class="mr-5">
                <h2
                  class="write-review"
                  @click="$bvModal.show('product-review')"
                >
                  Write Review
                </h2>
              </div>
            </div>
            <b-modal
              id="product-review"
              size="md"
              centered
              title="Review This Product"
              hide-footer
            >
              <v-container>
                <v-row>
                  <b-input-group>
                    <b-col md="12">
                      <div class="d-flex justify-content-start">
                        <div>
                          <b-img
                            id="product-img-1"
                            class="product-main-image"
                            :src="imagesArr[activeBox]"
                            alt
                            height="80"
                            width="80"
                          />
                        </div>
                        <div class="mt-3 ml-3">
                          <h4 style="color: #fd7e14">{{ productData.Name }}</h4>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div>
                        <h6 class="mb-0">Rate This Product</h6>
                        <b-form-rating
                          variant="warning"
                          class="pl-0 pr-0"
                          color="#fd7e14"
                          v-model="ratingStar"
                          inline
                          size="lg"
                        ></b-form-rating>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <h6>Additional Comments</h6>
                      <div class="mb-3">
                        <b-form-textarea
                          placeholder="Description"
                          class="mt-3"
                          rows="5"
                          v-model="feedbackComment"
                          style="background: #f8f8f8"
                        ></b-form-textarea>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="d-flex justify-content-center mt-2">
                        <button
                          @click="$bvModal.hide('product-review')"
                          type="button"
                          class="btn btn-outline-primary mr-3 pr-5 pl-5"
                        >
                          <span class="button-text">Clear</span>
                        </button>
                        <button type="button" class="btn btn-primary ml-3">
                          <span
                            class="button-text pr-5 pl-5"
                            @click="submitProductReview"
                            >Save</span
                          >
                        </button>
                      </div>
                    </b-col>
                  </b-input-group>
                </v-row>
              </v-container>
            </b-modal>
            <div class="d-flex" v-if="productData.providerName">
              <dt class="mr-1">Provider :</dt>
              <router-link
                :to="{
                  path: `/shopDetails/${productData.provider}/${productData.providerName}`,
                }"
                >{{ productData.providerName }}</router-link
              >
            </div>
            <!-- <div class="d-flex" v-if="productData.sellerContact">
              <dt class="mr-1">Contact :</dt>
              <dd class="underline-blue">{{ productData.sellerContact }}</dd>
            </div>-->
            <!-- <div v-if="productData['Discount(%)']">
              <dt>Offer Available:</dt>
              <dd>
                order above ₹ 500 get {{ productData["Discount(%)"] }}% off on
                first order for new users.
              </dd>
            </div>
            <div v-if="productData['Discount']">
              <dt>Offer Available:</dt>
              <dd>
                order above ₹ 500 get ₹{{ productData["Discount"] }} off on
                first order for new users.
              </dd>
            </div> -->
            <div class="product-details">
              <dt>Product Details:</dt>
              <dd v-if="productData.Name">
                Product Name : {{ productData.Name }}
              </dd>
              <dd v-if="productData.City && productData.State">
                City : {{ productData.City }} {{ productData.State }}
              </dd>
              <dd v-if="productData.Description">
                Description : {{ productData.Description }}
              </dd>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <div>
                <h4>Rating & Reviews</h4>
              </div>
              <div>
                <button class="btn btn-outline-primary">
                  <div
                    class="write-review-bottom pr-2 pl-2"
                    @click="$bvModal.show('product-review')"
                  >
                    <p class="mb-0">Write Review</p>
                  </div>
                </button>
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <div v-if="productFeedback">
              <div
                class="rating-reviews"
                v-for="rating in productFeedback.ratings"
                :key="rating.userId"
              >
                <div class="mb-4">
                  <div class="d-flex flex-row align-items-center">
                    <div class="d-flex flex-row star-count pl-1 pr-1">
                      <div class="mr-2">
                        <b-icon
                          icon="star-fill"
                          style="color: #fd7e14"
                        ></b-icon>
                      </div>
                      <div>
                        <p class="mb-0">{{ rating.stars }}</p>
                      </div>
                    </div>
                    <div class="ml-4">
                      <h6>{{ rating.givenBy.name.first }}</h6>
                    </div>
                  </div>
                  <div class="review-comment mt-2">
                    <p>{{ rating.feedbackComment }}</p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div v-else>
              <p>No reviews yet!</p>
            </div>
          </b-col>
        </b-row>
      </div>

      <sections-slider
        v-if="relatedItems && relatedItems.length"
        :list="relatedItems"
        subTitle="Related Products"
        :hasBackground="true"
        :showTitle="true"
        :hasButton="false"
        :withSlider="true"
        class="suggested-slider"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      items: {
        text: "Home",
        disabled: true,
        href: "/",
      },
      isLoading: false,
      topBanner: false,
      activeBox: 0,
      productId: "",
      productRatings: {
        ratings: [],
      },
      imagesArr: [],
      // selectedItemUnit: null,
      selectedProductVariantId: null,
      ratingStar: null,
      feedbackComment: "",
      baseProductId: null,
    };
  },

  computed: {
    ...mapState({
      listData: (state) => state.wishlist.listData,
      userInfo: (state) => state.auth.userInfo,
      isLoggedin: (state) => state.auth.isLoggedin,
      productVariantData: (state) => state.product.productVariantData,
      productFeedbackList: (state) => state.product.productFeedbackList,
    }),
    ...mapGetters({
      getProductData: "product/getProductData",
      getRelatedItems: "product/getRelatedItemsData",
      getProductFeedbackData: "product/getProductFeedbackData",
    }),
    wishlistKey() {
      const data = this.listData;
      const isAvailable = data.some((obj) => obj._id === this.productData._id);
      if (isAvailable) {
        return true;
      } else {
        return false;
      }
    },
    productData() {
      return this.getProductData(this.$route.params.data);
    },
    isVariationAvailable() {
      if (this.productData.variations) {
        return true;
      } else {
        return false;
      }
    },
    productVariation() {
      if (
        this.productData?.variations[0]?.attributes?.Quantity ===
        this.productData?.variations[1]?.attributes?.Quantity
      ) {
        return this.productData?.variations.slice(0, 1);
      } else {
        return this.productData?.variations;
      }
    },

    relatedItems() {
      return this.productData?.catalogId
        ? this.getRelatedItems(this.productData.catalogId)
        : [];
    },
    productFeedback() {
      return this.baseProductId
        ? this.getProductFeedbackData(this.baseProductId)
        : [];
    },
    ratingStarCount() {
      if (this.productFeedback) {
        return this.productFeedback.ratingAverage;
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWishlist: "wishlist/listWishList",
      fetchPartnerInfo: "shopDetails/fetchPartnerInfo",
    }),
    async selectUnit(unit) {
      let selectedItemUnit = unit;
      let selectedProductVariant = this.productData.variations.find(
        (variant) => variant.attributes.Quantity === selectedItemUnit
      );
      this.productData.Price = selectedProductVariant.Price;
      await this.$store
        .dispatch(
          "product/fetchProductVariantDetails",
          selectedProductVariant._id
        )
        .then((res) => {
          console.log(res);
          if (res) {
            this.productData.Quantity =
              selectedProductVariant.attributes.Quantity;
            this.productData._id = this.productVariantData._id;
            if (this.productData["Deal Price"]) {
              this.productData["Deal Price"] =
                this.productVariantData["Deal Price"];
            }
            if (this.productData["Discount(%)"]) {
              this.productData["Discount(%)"] =
                this.productVariantData["Discount(%)"];
            }
            if (this.productData["Discount"]) {
              this.productData["Discount"] =
                this.productVariantData["Discount"];
            }
          } else {
            this.$swal({
              title: "Opps!!!!!",
              text: "This combination is not available",
              type: "warning",
            });
          }
        });
    },

    async getProductDetails() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "product/fetchProductDetails",
          this.$route.params.data
        );

        this.imagesArr = [];
        for (const i in this.productData.images) {
          if (typeof this.productData.images[i] == "string") {
            this.imagesArr.push(this.productData.images[i]);
          }
        }
        this.isLoading = false;
        await this.$store.dispatch(
          "product/fetchRelatedItemData",
          this.productData.catalogId
        );
        await this.$store.dispatch(
          "product/fetchProductFeedback",
          this.productData._id
        );
      } catch (e) {
        console.log(e);
      }
    },

    async submitProductReview() {
      try {
        let payload = {
          subjectId: this.baseProductId,
          ratings: {
            stars: this.ratingStar,
            feedbackComment: this.feedbackComment,
            givenBy: {
              name: {
                first: this.userInfo.name.first,
                last: this.userInfo.name.last,
              },
            },
          },
        };
        await this.$store
          .dispatch("product/updateProductReview", payload)
          .then(async (res) => {
            console.log(res, "feedback submitted");
            this.$swal({
              title: "Success!",
              text: "Thanks for the feedback on your experience",
              type: "success",
            });
            this.$bvModal.hide("product-review");
            await this.$store.dispatch(
              "product/fetchProductFeedback",
              this.baseProductId
            );
            this.ratingStar = null;
            this.feedbackComment = "";
          });
      } catch (err) {
        this.$swal({
          title: "Opps!!!!!",
          text: "Not Submitted",
          type: "warning",
        });
        this.$bvModal.hide("product-review");
        console.log(err);
      }
    },

    async addToWishlist(productData) {
      if (!this.isLoggedin) {
        this.$router.push("/login");
      } else {
        await this.$store.dispatch("wishlist/addToWishlist", productData);
        this.$swal({
          title: "Success",
          text: "Added to your Wishlist",
          type: "success",
          timer: 1000,
        });
      }
    },
    async removeItem(productData) {
      await this.$store.dispatch("wishlist/removeToWishList", productData);
      this.$swal({
        group: "item",
        title: "Success",
        text: "Removed from your Wishlist",
        type: "success",
        timer: 1000,
      });
    },
    setActiveBox(i) {
      this.activeBox = i;
    },
  },
  onClickButton() {
    this.showButton = true;
  },
  async mounted() {
    this.$store.commit("theme/updateTheme", {});
    this.$store.commit("theme/updateName", "");
    await this.getProductDetails();
    this.baseProductId = this.productData._id;
    await this.$store.dispatch(
      "shopDetails/fetchPartnerInfo",
      this.productData.provider
    );
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.productdetail-imgshort {
  max-width: 100px;
  max-height: 100px;
  text-align: center;
}

.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}

.show-variant {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}

.variant-span {
  position: absolute;
  background-color: #fd7e14;
  padding: 4px 10px 4px 25px;
  font-size: 20px;
  color: #fff;
  bottom: 12px;
  right: 0;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%);
  font-weight: 400;
}

.v-icon.v-icon {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0px !important;
}

.product-section {
  margin-top: 0px;
  padding: 0px 0 43px 0;
}

.product-add svg {
  font-size: 14px;
}

.product-images {
  padding: 15px;
}

.selectedUnit {
  color: #fd7e14 !important;
  border-color: #fd7e14 !important;
}

.item-quantity {
  border: 1px solid #b8b8b8;
  border-radius: 4px;
}

.add-button {
  width: 30%;
}

#add-cart {
  font-size: 14px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 2px;
}

.product-icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #e8e8e8;
  width: 45px;
  height: 45px;
  font-size: 25px;
}

.heart {
  color: #ff6d59;
}

.message {
  color: #41a9d6;
}

.product-main h1 {
  font-size: 26px;
}

.product-location {
  font-size: 16px;
  color: #a4a4a4;
}

.product-main h2 {
  font-size: 21px;
}

.product-main dd dt {
  font-size: 14px;
}

.product-details dd {
  font-size: 13px;
}

.product-stars {
  margin-left: 15px;
  margin-right: 15px;
}

.product-star::before {
  width: 25px;
  font-size: 25px;
  color: var(--primary);
}

.write-review {
  color: #fd7e14;
  text-decoration: underline;
  cursor: pointer;
}

.star-count {
  border: 1px solid #000000;
  border-radius: 4px;
}

.product-price {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.product-count {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.product-count input {
  width: 40px;
  height: 30px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
}

.product-count-icon {
  border-radius: 50%;
  border: 2px solid black;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#unit-button-box {
  display: flex;
  justify-content: center;
  gap: 8px;
}

#unit-button-box button {
  color: black;
  width: 65px;
  font-size: 13px;
  height: 30px;
  background-color: transparent;
  border: #b8b8b8 1px solid;
  border-radius: 4px;
}

#unit-button-box button:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.product-rate {
  margin-bottom: 12px;
}

.product-info .text-icon::before {
  width: 20px;
  position: relative;
  right: 3px;
}

.product-info button {
  background-color: var(--primary);
  color: white;
  width: 140px;
  font-size: 16px;
  height: 45px;
  border-radius: 3px;
}

.product-address {
  font-size: 16px;
  color: #a4a4a4;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
}

.text-no-href {
  font-size: 16px;
  color: #a4a4a4;
  margin-bottom: 20px;
  text-decoration: none;
}

.product-main-image {
  object-fit: contain;
}

.product-thumb-image {
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 0.25rem;
  object-fit: contain;
}

.product-border {
  border: 1px solid #f2f2f2;
  position: relative;
}

.underline-blue {
  color: #0275d8;
  text-decoration: underline;
}

.red-link {
  color: #ff3535;
  text-decoration: underline;
  font-size: 21px;
  margin-left: 72px;
}

.form-control {
  border: none;
}

.lds-roller {
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
}

@media (max-width: 768px) {
  .product-price-mobile {
    width: 80%;
  }

  .add-button-mobile {
    width: 100%;
  }

  .product-main-image {
    width: 100%;
  }

  .product-thumb-image {
    width: 100%;
  }

  .price-del-width {
    width: 80%;
  }
}
</style>
